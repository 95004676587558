body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background-color: #f0f0f0;
}
*[data-react-beautiful-dnd-droppable] {
    width: 100%;
}

.MuiAutocomplete-popper {
    width: calc(100vmin / 2) !important;
    position: absolute;
    top: 50px;
    left: calc((((100vmin / 2) / 3) * -1));
}

.MuiAutocomplete-listbox {
    height: 150px;
}

.ReactVirtualized__List:focus {
    outline: 0;
}
